// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");
var Col2$BsConsole = require("./components/Col2.js");
var Crdb$BsConsole = require("./crdb.js");
var Gate$BsConsole = require("./Gate.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Text$BsConsole = require("./Text.js");
var Util$BsConsole = require("./util.js");
var Route$BsConsole = require("./route.js");
var Colors$BsConsole = require("./Colors.js");
var IFrame$BsConsole = require("./IFrame.js");
var TxTask$BsConsole = require("./TxTask.js");
var BtPaper$BsConsole = require("./BtPaper.js");
var Current$BsConsole = require("./saved-queries/Current.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var ViewNav$BsConsole = require("./ViewNav.js");
var BinModal$BsConsole = require("./BinModal.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var ObjectID$BsConsole = require("./ObjectID.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var TriageData$BsConsole = require("./TriageData.js");
var WfLinkUtil$BsConsole = require("./workflow/WfLinkUtil.js");
var TriageUtils$BsConsole = require("./TriageUtils.js");
var ViewLoading$BsConsole = require("./ViewLoading.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var TriageHeader$BsConsole = require("./TriageHeader.js");
var Paper = require("@material-ui/core/Paper");
var Save = require("@material-ui/icons/Save");
var InboxWorkflow$BsConsole = require("./inbox/InboxWorkflow.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var TriageDetails$BsConsole = require("./TriageDetails.js");
var Button = require("@material-ui/core/Button");
var BacktraceHooks$BsConsole = require("./BacktraceHooks.js");
var BtTableAdapter$BsConsole = require("./BtTableAdapter.js");
var InboxMultiTags$BsConsole = require("./inbox/InboxMultiTags.js");
var BtEnhancedTable$BsConsole = require("./BtEnhancedTable.js");
var IdentityVariant$BsConsole = require("./IdentityVariant.js");
var TriageTagSelect$BsConsole = require("./TriageTagSelect.js");
var DrilldownContext$BsConsole = require("./DrilldownContext.js");
var DemoProjectPrompt$BsConsole = require("./DemoProjectPrompt.js");
var DistributionModal$BsConsole = require("./DistributionModal.js");
var NormalizeBySelect$BsConsole = require("./NormalizeBySelect.js");
var SavedQueriesModel$BsConsole = require("./saved-queries/SavedQueriesModel.js");
var TriageIssueRemote$BsConsole = require("./TriageIssueRemote.js");
var BtTableSessionData$BsConsole = require("./BtTableSessionData.js");
var TriageStatusSelect$BsConsole = require("./TriageStatusSelect.js");
var TriageAssigneeSelect$BsConsole = require("./TriageAssigneeSelect.js");
var SavedQueriesContainer$BsConsole = require("./saved-queries/SavedQueriesContainer.js");
var ErrorsOverTimeBinChart$BsConsole = require("./ErrorsOverTimeBinChart.js");
var FingerprintDescription$BsConsole = require("./FingerprintDescription.js");
var UniqueAggregationsTask$BsConsole = require("./UniqueAggregationsTask.js");
var DistributionValueSelect$BsConsole = require("./DistributionValueSelect.js");
var FetchAvailableWorkflows$BsConsole = require("./FetchAvailableWorkflows.js");
var SavedQueryBookmarkSelect$BsConsole = require("./saved-queries/SavedQueryBookmarkSelect.js");

var Style = {
  paper: BtPaper$BsConsole.mainViewMargins
};

function stripTimestampFilters(aperture) {
  var f = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
  var f$prime = Curry._2(Crdb$BsConsole.Filters.keep, (function (filter) {
          return Curry._1(Crdb$BsConsole.Filter.getAttribute, filter) !== "timestamp";
        }), f);
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, f$prime, aperture);
}

function getDetailsFingerprintFromAperture(aperture) {
  return Belt_Array.get(Belt_Array.keepMap(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), (function (filter) {
                    if (Curry._1(Crdb$BsConsole.Filter.getAttribute, filter) === "fingerprint") {
                      return Json_decode.string(Crdb$BsConsole.FilterOperation.valueToJson(Crdb$BsConsole.FilterOperation.getValue(Curry._1(Crdb$BsConsole.Filter.getOperation, filter))));
                    }
                    
                  })), 0);
}

function ProjectTriage(Props) {
  var fold = Props.fold;
  var view = Props.view;
  var attributes = Props.attributes;
  var havings = Props.havings;
  var aperture = Props.aperture;
  var config = Props.config;
  var sort = Props.sort;
  var stats = Props.stats;
  var token = Props.token;
  var handleTask = Props.handleTask;
  var projectName = Props.projectName;
  Props.universe;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var route = Props.route;
  var normBy = Props.normBy;
  var similarity = Props.similarity;
  var metricsAttributesOpt = Props.metricsAttributes;
  var stabilityScoreOpt = Props.stabilityScore;
  var maybeWfLinkTx = Props.maybeWfLinkTx;
  var metricsAttributes = metricsAttributesOpt !== undefined ? metricsAttributesOpt : /* [] */0;
  var stabilityScore = stabilityScoreOpt !== undefined ? Caml_option.valFromOption(stabilityScoreOpt) : undefined;
  var handleSetAperture = function (aperture$prime) {
    var init = Route$BsConsole.getInboxParams(projectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                  projectName,
                  {
                    aperture: aperture$prime,
                    sort: sort,
                    stats: init.stats,
                    havings: havings,
                    fold: fold,
                    normBy: normBy,
                    similarity: init.similarity
                  },
                  undefined
                ]));
  };
  var handleSetMetricGroup = function (normBy$prime) {
    var init = Route$BsConsole.getInboxParams(projectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                  projectName,
                  {
                    aperture: aperture,
                    sort: sort,
                    stats: init.stats,
                    havings: havings,
                    fold: fold,
                    normBy: normBy$prime,
                    similarity: init.similarity
                  },
                  undefined
                ]));
  };
  var handleSetSort = function (sort$prime) {
    var init = Route$BsConsole.getInboxParams(projectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                  projectName,
                  {
                    aperture: aperture,
                    sort: sort$prime,
                    stats: init.stats,
                    havings: havings,
                    fold: fold,
                    normBy: normBy,
                    similarity: init.similarity
                  },
                  undefined
                ]));
  };
  var handleSetFold = function (fold$prime) {
    var init = Route$BsConsole.getInboxParams(projectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                  projectName,
                  {
                    aperture: aperture,
                    sort: sort,
                    stats: init.stats,
                    havings: havings,
                    fold: fold$prime,
                    normBy: normBy,
                    similarity: init.similarity
                  },
                  undefined
                ]));
  };
  var match = React.useState((function () {
          if (Belt_Option.getWithDefault(similarity, false)) {
            return /* SimilarityTab */1;
          }
          
        }));
  var setDetailsTab = match[1];
  var detailsTab = match[0];
  var match$1 = React.useState((function () {
          return [];
        }));
  var setSelection = match$1[1];
  var selection = match$1[0];
  var apertureForQuery = BacktraceHooks$BsConsole.FrozenTimeAperture.use(aperture);
  var query = TriageUtils$BsConsole.makeQuery(stats, apertureForQuery, havings, view, sort, attributes, fold);
  var queryKey = JSON.stringify(Curry._1(Crdb$BsConsole.Query.toJson, query));
  var maybeCurrentProject = Belt_List.getBy(config.projects, (function (p) {
          return p.name === projectName;
        }));
  var match$2 = React.useState((function () {
          
        }));
  var setExpansionModal = match$2[1];
  var expansionModal = match$2[0];
  var uniqueCount = UniqueAggregationsTask$BsConsole.useCount(query, projectName, config, token, /* Identity */0);
  var match$3 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setFingerprintDescriptionRemote = match$3[1];
  var fingerprintDescriptionRemote = match$3[0];
  var match$4 = TriageData$BsConsole.useTableData(projectName, token, query, normBy, sort, attributes, uniqueCount);
  var fetchIssues = match$4[2];
  var tableDataRemote = match$4[0];
  var match$5 = TxTask$BsConsole.use(token, projectName);
  var txRemote = match$5[0];
  var match$6 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setFingerprintOfTxRemote = match$6[1];
  var fingerprintOfTxRemote = match$6[0];
  var workflows;
  if (maybeCurrentProject !== undefined) {
    workflows = FetchAvailableWorkflows$BsConsole.use(config, maybeCurrentProject, token);
  } else {
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ProjectTriage/workflows", Backtrace$BsConsole.Attributes.make(undefined));
    Backtrace$BsConsole.Client.send(__x, /* `string */[
          288368849,
          "Project name " + (projectName + " is not a valid project.")
        ]);
    workflows = [];
  }
  var match$7 = InboxMultiTags$BsConsole.useAllTags(projectName, token);
  var refreshAllTags = match$7[1];
  var allTagsRemote = match$7[0];
  var allTags;
  allTags = typeof allTagsRemote === "number" ? [] : (
      allTagsRemote.tag ? [] : allTagsRemote[0]
    );
  var partial_arg = Curry._1(Crdb$BsConsole.Query.getAperture, query);
  var getDetails = function (param) {
    return InboxWorkflow$BsConsole.Folders.getDetails(partial_arg, (function (fm) {
                  var init = Route$BsConsole.getInboxParams(projectName, Caml_option.some(Curry._2(Crdb$BsConsole.Aperture.setFilters, fm, aperture)), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
                  return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                                projectName,
                                {
                                  aperture: init.aperture,
                                  sort: init.sort,
                                  stats: stats,
                                  havings: init.havings,
                                  fold: fold,
                                  normBy: init.normBy,
                                  similarity: init.similarity
                                },
                                undefined
                              ]));
                }), param);
  };
  var handleSetIssue = function (param, param$1, param$2) {
    return TriageIssueRemote$BsConsole.setIssue((function (settingSets) {
                  var match = Belt_List.get(settingSets, 0);
                  if (match !== undefined) {
                    return Belt_List.length(match[1]) > 0;
                  } else {
                    return false;
                  }
                }), (function (fingerprint, result) {
                  TriageIssueRemote$BsConsole.$$Response.toast(fingerprint, Caml_option.some(getDetails), result);
                  Curry._1(fetchIssues, [fingerprint]);
                  
                }), token, projectName, param, param$1, param$2);
  };
  var cbFailure = function (param) {
    return Curry._1(setFingerprintOfTxRemote, (function (param) {
                  return /* Success */Block.__(0, [undefined]);
                }));
  };
  var cbSuccess = function (fingerprint) {
    return Curry._1(setFingerprintOfTxRemote, (function (param) {
                  return /* Success */Block.__(0, [fingerprint]);
                }));
  };
  React.useEffect((function () {
          var match = Gate$BsConsole.isBeta(undefined);
          if (match && typeof tableDataRemote !== "number" && !tableDataRemote.tag) {
            var match$1 = tableDataRemote[0];
            FingerprintDescription$BsConsole.queryDescription(token, match$1.meta.tx, match$1.page, projectName, Curry._1(Crdb$BsConsole.Query.getAperture, match$1.requestQuery), (function (descriptionMap) {
                    return Curry._1(setFingerprintDescriptionRemote, (function (param) {
                                  return /* Success */Block.__(0, [descriptionMap]);
                                }));
                  }), (function (err) {
                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error generating group descriptions"));
                    return Curry._1(setFingerprintDescriptionRemote, (function (param) {
                                  return /* Failure */Block.__(1, [err]);
                                }));
                  }));
          }
          
        }), [tableDataRemote]);
  React.useEffect((function () {
          if (maybeWfLinkTx !== undefined) {
            Curry._1(setFingerprintOfTxRemote, (function (param) {
                    return /* Loading */1;
                  }));
            WfLinkUtil$BsConsole.getFingerprintOfTx(token, projectName, maybeWfLinkTx, cbSuccess, cbFailure);
          } else {
            Curry._1(setFingerprintOfTxRemote, (function (param) {
                    return /* Success */Block.__(0, [undefined]);
                  }));
          }
          
        }), [maybeWfLinkTx]);
  React.useEffect((function () {
          if (typeof fingerprintOfTxRemote !== "number" && !fingerprintOfTxRemote.tag && typeof tableDataRemote !== "number" && !tableDataRemote.tag && view) {
            var maybeTxFingerprint = fingerprintOfTxRemote[0];
            var maybeDetailsFingerprint = getDetailsFingerprintFromAperture(Curry._1(Crdb$BsConsole.Query.getAperture, tableDataRemote[0].requestQuery));
            if (maybeTxFingerprint !== undefined && maybeDetailsFingerprint !== undefined) {
              if (maybeTxFingerprint !== maybeDetailsFingerprint) {
                MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                        /* WorkflowLinkFingerprintMismatch */134,
                        Json_encode.object_(/* :: */[
                              /* tuple */[
                                "link_tx",
                                maybeWfLinkTx !== undefined ? ObjectID$BsConsole.toHexString(maybeWfLinkTx) : "n/a"
                              ],
                              /* :: */[
                                /* tuple */[
                                  "link_correctFingerprint",
                                  maybeTxFingerprint
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "link_providedFingerprint",
                                    maybeDetailsFingerprint
                                  ],
                                  /* [] */0
                                ]
                              ]
                            ])
                      ]));
                var __x = Curry._1(Crdb$BsConsole.Filters.fromArray, Belt_Array.keep(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), (function (filter) {
                            var filterAttribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
                            var filterOperation = Curry._1(Crdb$BsConsole.Filter.getOperation, filter);
                            if (filterAttribute !== "fingerprint") {
                              return true;
                            }
                            if (filterOperation.tag) {
                              return true;
                            }
                            var match = filterOperation[0];
                            if (typeof match === "number") {
                              return true;
                            } else {
                              return match[0] !== -976970511;
                            }
                          })));
                handleSetAperture(Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                  "fingerprint",
                                  /* Equal */Block.__(0, [/* `String */[
                                        -976970511,
                                        maybeTxFingerprint
                                      ]])
                                ]), __x), aperture));
                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Fingerprint mismatch detected. The details view has been updated to show the correct fingerprint."));
              } else {
                Curry._1(setFingerprintOfTxRemote, (function (param) {
                        return /* Success */Block.__(0, [undefined]);
                      }));
                MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* WorkflowLinkFingerprintMatch */133]));
              }
            }
            
          }
          
        }), /* tuple */[
        fingerprintOfTxRemote,
        tableDataRemote,
        view
      ]);
  React.useEffect((function () {
          Curry._1(setSelection, (function (param) {
                  return [];
                }));
          
        }), [queryKey]);
  BacktraceHooks$BsConsole.useSortRecover(sort, query, (function (param) {
          return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                        projectName,
                        Route$BsConsole.getInboxParams(projectName, Caml_option.some(aperture), Caml_option.some(havings), undefined, Caml_option.some(fold), Crdb$BsConsole.Sort.defaultAggregate, normBy, undefined, undefined),
                        undefined
                      ]));
        }));
  React.useEffect((function () {
          if (typeof tableDataRemote !== "number" && !tableDataRemote.tag && view) {
            var match = tableDataRemote[0];
            var rows = match.rows;
            var detailsFingerprint = getDetailsFingerprintFromAperture(Curry._1(Crdb$BsConsole.Query.getAperture, match.requestQuery));
            var selectedRow = Belt_Option.flatMap(detailsFingerprint, (function (fingerprint) {
                    return Belt_Array.get(Belt_Array.keep(rows, (function (row) {
                                      return row.fingerprint === fingerprint;
                                    })), 0);
                  }));
            if (Belt_Option.isSome(detailsFingerprint) && Belt_Option.isNone(selectedRow) && rows.length !== 0) {
              Belt_Option.map(Belt_Option.map(Belt_Array.get(rows, 0), (function (row) {
                          return row.fingerprint;
                        })), (function (fingerprint) {
                      var __x = Curry._1(Crdb$BsConsole.Filters.fromArray, Belt_Array.keep(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), (function (filter) {
                                  var filterAttribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
                                  var filterOperation = Curry._1(Crdb$BsConsole.Filter.getOperation, filter);
                                  if (filterAttribute !== "fingerprint") {
                                    return true;
                                  }
                                  if (filterOperation.tag) {
                                    return true;
                                  }
                                  var match = filterOperation[0];
                                  if (typeof match === "number") {
                                    return true;
                                  } else {
                                    return match[0] !== -976970511;
                                  }
                                })));
                      return handleSetAperture(Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                              "fingerprint",
                                              /* Equal */Block.__(0, [/* `String */[
                                                    -976970511,
                                                    fingerprint
                                                  ]])
                                            ]), __x), aperture));
                    }));
            }
            
          }
          
        }), /* tuple */[
        tableDataRemote,
        view
      ]);
  React.useEffect((function () {
          var savedAttrs = Belt_Option.getWithDefault(Belt_Option.flatMap(config.userSettings, (function (settings) {
                      return settings.fingerprintDetailsAttrs;
                    })), /* [] */0);
          Belt_List.forEach(savedAttrs, (function (attr) {
                  if (Belt_List.has(stats, attr, (function (prim, prim$1) {
                            return prim === prim$1;
                          }))) {
                    return ;
                  }
                  var newStats = Util$BsConsole.removeDuplicates(Belt_List.concat(stats, savedAttrs));
                  return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                                projectName,
                                Route$BsConsole.getInboxParams(projectName, Caml_option.some(aperture), undefined, newStats, undefined, undefined, undefined, undefined, undefined),
                                undefined
                              ]));
                }));
          
        }), ([]));
  React.useEffect((function () {
          if (view) {
            
          } else {
            BtTableSessionData$BsConsole.Query.set(projectName, query, /* Triage */0);
          }
          
        }), /* tuple */[
        query,
        view,
        projectName
      ]);
  var match$8 = React.useMemo((function () {
          if (typeof tableDataRemote === "number") {
            return /* tuple */[
                    [],
                    []
                  ];
          }
          if (tableDataRemote.tag) {
            return /* tuple */[
                    [],
                    []
                  ];
          }
          var match = tableDataRemote[0];
          return /* tuple */[
                  match.projectUsers,
                  match.rows
                ];
        }), [tableDataRemote]);
  var mode = SavedQueriesModel$BsConsole.modeOfStringExn("inbox");
  var queryForSavedQueryContainer = TriageUtils$BsConsole.makeQuery(stats, aperture, havings, view, sort, attributes, fold);
  var tmp;
  tmp = typeof txRemote === "number" || txRemote.tag || txRemote[0] !== 1 ? null : React.createElement(DemoProjectPrompt$BsConsole.make, {
          config: config,
          token: token,
          bannerVariant: /* Triage */2,
          handleChangeUrl: handleChangeUrl,
          projectName: projectName,
          styles: Css.style(/* :: */[
                Css.transform(Css.translateY(Css.px(15))),
                /* :: */[
                  Css.marginTop(Css.px(5)),
                  /* :: */[
                    Css.marginRight(Css.px(16)),
                    /* :: */[
                      Css.marginLeft(Css.px(16)),
                      /* [] */0
                    ]
                  ]
                ]
              ])
        });
  var tmp$1;
  var exit = 0;
  var msg;
  var exit$1 = 0;
  if (typeof tableDataRemote === "number") {
    exit = 1;
  } else if (tableDataRemote.tag) {
    exit$1 = 3;
  } else {
    var match$9 = tableDataRemote[0];
    var requestQuery = match$9.requestQuery;
    var meta = match$9.meta;
    var setPage = match$9.setPage;
    var rows = match$9.rows;
    var columns = match$9.columns;
    if (typeof fingerprintOfTxRemote === "number") {
      exit$1 = 3;
    } else if (fingerprintOfTxRemote.tag) {
      msg = fingerprintOfTxRemote[0];
      exit = 2;
    } else {
      var projectUsers = Belt_Array.keep(match$9.projectUsers, (function (user) {
              return !Util$BsConsole.shouldFilterBacktraceInternalUser(user.email, config.universe.name, IdentityVariant$BsConsole.current === /* Unity */2);
            }));
      var tmp$2;
      if (view) {
        var selectedRow = Belt_Option.flatMap(getDetailsFingerprintFromAperture(Curry._1(Crdb$BsConsole.Query.getAperture, requestQuery)), (function (fingerprint) {
                return Belt_Array.get(Belt_Array.keep(rows, (function (row) {
                                  return row.fingerprint === fingerprint;
                                })), 0);
              }));
        var match$10 = rows.length;
        var match$11 = Belt_List.get(match$9.groups, 0);
        if (selectedRow !== undefined) {
          if (match$11 !== undefined && match$10 > 0) {
            var match$12 = Gate$BsConsole.isBeta(undefined);
            var tmp$3;
            if (match$12 && !(typeof fingerprintDescriptionRemote === "number" || fingerprintDescriptionRemote.tag)) {
              var description = Belt_MapString.get(fingerprintDescriptionRemote[0], selectedRow.fingerprint);
              tmp$3 = {
                count: selectedRow.count,
                fingerprint: selectedRow.fingerprint,
                description: description,
                callstack: selectedRow.callstack,
                timestampRange: selectedRow.timestampRange,
                timestampBin: selectedRow.timestampBin,
                firstSeen: selectedRow.firstSeen,
                aggregations: selectedRow.aggregations,
                issues: selectedRow.issues
              };
            } else {
              tmp$3 = selectedRow;
            }
            tmp$2 = React.createElement(TriageDetails$BsConsole.Jsx3.make, {
                  triageGroup: tmp$3,
                  fetchIssues: fetchIssues,
                  projectName: projectName,
                  token: token,
                  config: config,
                  columns: match$9.columnDescs,
                  group: match$11,
                  handleTask: handleTask,
                  stats: stats,
                  tab: detailsTab !== undefined ? detailsTab : /* OverviewTab */0,
                  handleChangeUrl: handleChangeUrl,
                  aperture: aperture,
                  handleAddFilters: handleAddFilters,
                  handleSetDetailsTab: (function (tab) {
                      return Curry._1(setDetailsTab, (function (param) {
                                    return tab;
                                  }));
                    })
                });
          } else {
            tmp$2 = I18N$BsConsole.show(undefined, "No group found");
          }
        } else {
          tmp$2 = match$11 !== undefined && match$10 > 0 ? React.createElement(ViewLoading$BsConsole.Jsx3.make, {
                  label: "Fetching triage data"
                }) : I18N$BsConsole.show(undefined, "No group found");
        }
      } else {
        var match$13 = rows.length;
        if (match$13 !== 0) {
          var match$14 = Gate$BsConsole.isBeta(undefined);
          var tmp$4;
          var exit$2 = 0;
          if (match$14 && (typeof fingerprintDescriptionRemote === "number" || fingerprintDescriptionRemote.tag !== /* Failure */1)) {
            tmp$4 = columns;
          } else {
            exit$2 = 4;
          }
          if (exit$2 === 4) {
            tmp$4 = Belt_Array.keep(columns, (function (column) {
                    return column.name !== "__description";
                  }));
          }
          var match$15 = Gate$BsConsole.isBeta(undefined);
          var tmp$5;
          if (match$15 && !(typeof fingerprintDescriptionRemote === "number" || fingerprintDescriptionRemote.tag)) {
            var descriptionsMap = fingerprintDescriptionRemote[0];
            tmp$5 = Belt_Array.map(rows, (function (row) {
                    var description = Belt_MapString.get(descriptionsMap, row.fingerprint);
                    return {
                            count: row.count,
                            fingerprint: row.fingerprint,
                            description: description,
                            callstack: row.callstack,
                            timestampRange: row.timestampRange,
                            timestampBin: row.timestampBin,
                            firstSeen: row.firstSeen,
                            aggregations: row.aggregations,
                            issues: row.issues
                          };
                  }));
          } else {
            tmp$5 = rows;
          }
          var arg = Curry._1(Crdb$BsConsole.Query.getAperture, requestQuery);
          var arg$1 = Belt_Option.getWithDefault(meta.instances, 1);
          var arg$2 = function (modal) {
            return Curry._1(setExpansionModal, (function (param) {
                          return modal;
                        }));
          };
          var arg$3 = function (columnAggregation) {
            if (columnAggregation === undefined) {
              return ;
            }
            var agg = Caml_option.valFromOption(columnAggregation);
            var aggToDelete = Belt_Array.getBy(Curry._1(Crdb$BsConsole.Fold.toArray, fold), (function (agg$prime) {
                    if (Curry._1(Crdb$BsConsole.Aggregation.getAttribute, agg) === Curry._1(Crdb$BsConsole.Aggregation.getAttribute, agg$prime)) {
                      return Crdb$BsConsole.Operation.toString(Curry._1(Crdb$BsConsole.Aggregation.getOperation, agg)) === Crdb$BsConsole.Operation.toString(Curry._1(Crdb$BsConsole.Aggregation.getOperation, agg$prime));
                    } else {
                      return false;
                    }
                  }));
            if (aggToDelete !== undefined) {
              return handleSetFold(Curry._2(Crdb$BsConsole.Fold.remove, Caml_option.valFromOption(aggToDelete), fold));
            }
            
          };
          var tmp$6 = {
            columns: tmp$4,
            rows: tmp$5,
            getRowId: TriageData$BsConsole.getRowId,
            renderDataCell: (function (param) {
                var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22) {
                  return TriageData$BsConsole.renderTriageDataCell(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22);
                };
                return (function (param) {
                    var func$1 = Curry._1(func, param);
                    return (function (param) {
                        var func$2 = Curry._1(func$1, param);
                        return (function (param) {
                            return Curry.app(func$2, [
                                        param,
                                        projectName,
                                        handleChangeUrl,
                                        handleAddFilters,
                                        aperture,
                                        arg,
                                        arg$1,
                                        stabilityScore,
                                        normBy,
                                        arg$2,
                                        sort,
                                        config,
                                        handleSetIssue,
                                        allTags,
                                        refreshAllTags,
                                        projectUsers,
                                        workflows,
                                        fetchIssues,
                                        token,
                                        uniqueCount
                                      ]);
                          });
                      });
                  });
              }),
            renderHeaderCell: (function (param) {
                var func = function (param$1, param$2, param$3, param$4, param$5) {
                  return TriageData$BsConsole.renderHeaderCell(param, param$1, param$2, param$3, param$4, param$5);
                };
                return (function (param) {
                    var func$1 = Curry._1(func, param);
                    return (function (param) {
                        var func$2 = Curry._1(func$1, param);
                        return (function (param) {
                            return Curry._3(func$2, param, arg$3, handleSetSort);
                          });
                      });
                  });
              }),
            renderStubHeader: (function (param) {
                return TriageData$BsConsole.renderStubHeader(query, attributes, (function (agg) {
                              return handleSetFold(Curry._2(Crdb$BsConsole.Fold.add, agg, fold));
                            }), param);
              }),
            stubMinWidth: 175,
            onSortChange: (function (param) {
                return BtTableAdapter$BsConsole.CrdbAggregate.onSortChange(handleSetSort, param);
              }),
            onSelectedChange: (function (selected) {
                return Curry._1(setSelection, (function (param) {
                              return selected;
                            }));
              }),
            selected: selection,
            onColumnOrderChange: (function (columnOrder) {
                return BtTableSessionData$BsConsole.ColumnOrder.set(projectName, columnOrder, /* Triage */0);
              }),
            resizeableColumns: true,
            draggableColumns: true,
            zebraStripe: true,
            page: match$9.page,
            onChangePage: Curry.__1(setPage),
            totalRows: Belt_Option.getWithDefault(meta.groups, 0),
            loading: match$9.loading,
            containerClassName: match$9.containerClassName,
            onColumnWidthChange: (function (columnWidths) {
                return BtTableSessionData$BsConsole.ColumnWidth.set(projectName, columnWidths, /* Triage */0);
              })
          };
          var tmp$7 = match$4[1];
          if (tmp$7 !== undefined) {
            tmp$6.sortingState = Caml_option.valFromOption(tmp$7);
          }
          var tmp$8 = IFrame$BsConsole.isIFrame ? undefined : [
              TriageData$BsConsole.statusColumn,
              TriageData$BsConsole.fingerprintColumn,
              TriageData$BsConsole.descriptionColumn
            ];
          if (tmp$8 !== undefined) {
            tmp$6.fixedLeftColumns = Caml_option.valFromOption(tmp$8);
          }
          if (match$9.columnOrder !== undefined) {
            tmp$6.columnOrder = Caml_option.valFromOption(match$9.columnOrder);
          }
          if (match$9.columnWidths !== undefined) {
            tmp$6.columnWidths = Caml_option.valFromOption(match$9.columnWidths);
          }
          tmp$2 = React.createElement(DrilldownContext$BsConsole.Provider.make, {
                handleChangeUrl: handleChangeUrl,
                handleAddFilters: handleAddFilters,
                children: React.createElement(BtEnhancedTable$BsConsole.make, tmp$6)
              });
        } else {
          tmp$2 = React.createElement(Col2$BsConsole.make, {
                alignItems: /* center */98248149,
                justifyContent: /* center */98248149,
                className: Css.style(/* :: */[
                      Css.minHeight(Css.px(175)),
                      /* :: */[
                        Css.padding(Css.rem(2)),
                        /* :: */[
                          Css.color(Css.hex(Colors$BsConsole.grey3)),
                          /* [] */0
                        ]
                      ]
                    ]),
                children: Caml_int64.gt(meta.tx, Caml_int64.one) ? React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Heading1 */0,
                            children: Text$BsConsole.get(/* Triage_Empty_Title */2)
                          }), React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Subtitle1 */6,
                            children: Text$BsConsole.get(/* Triage_Empty_Description */3)
                          })) : React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Heading1 */0,
                            children: Text$BsConsole.get(/* Welcome */8)
                          }), React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Subtitle1 */6,
                            children: Text$BsConsole.get(/* No_Crashes_Uploaded */9)
                          }), React.createElement(Button.default, {
                            variant: "contained",
                            color: "primary",
                            className: Css.style(/* :: */[
                                  Css.marginTop(Css.rem(1)),
                                  /* [] */0
                                ]),
                            onClick: (function (_event) {
                                if (IdentityVariant$BsConsole.current === /* Unity */2) {
                                  return Util$BsConsole.openUrl("https://docs.unity.com/ugs/en-us/manual/cloud-diagnostics-advanced/manual/Setup");
                                } else {
                                  return Curry._1(handleChangeUrl, /* ProjectSettingsIntegratedDocs */Block.__(28, [
                                                projectName,
                                                {
                                                  doc: undefined
                                                }
                                              ]));
                                }
                              }),
                            children: I18N$BsConsole.show(undefined, "Get started")
                          }))
              });
        }
      }
      tmp$1 = React.createElement(React.Fragment, undefined, tmp$2);
    }
  }
  if (exit$1 === 3) {
    if (typeof fingerprintOfTxRemote === "number") {
      exit = 1;
    } else {
      msg = tableDataRemote[0];
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        tmp$1 = React.createElement(ViewLoading$BsConsole.Jsx3.make, {
              label: "Fetching triage data"
            });
        break;
    case 2 :
        tmp$1 = React.createElement(Col2$BsConsole.make, {
              alignItems: /* center */98248149,
              justifyContent: /* spaceAround */-485895757,
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.minHeight(Css.px(400)),
                      /* :: */[
                        Css.paddingBottom(Css.px(8)),
                        /* :: */[
                          Css.fontSize(Css.px(20)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]),
              children: I18N$BsConsole.dynamic(msg)
            });
        break;
    
  }
  var tmp$9;
  tmp$9 = expansionModal !== undefined ? (
      expansionModal.tag ? React.createElement(BinModal$BsConsole.Jsx3.make, {
              onClose: (function (param) {
                  return Curry._1(setExpansionModal, (function (param) {
                                
                              }));
                }),
              attribute: expansionModal[1],
              factorName: "fingerprint",
              factorValue: expansionModal[0],
              data: expansionModal[2]
            }) : React.createElement(DistributionModal$BsConsole.Jsx3.make, {
              onClose: (function (param) {
                  return Curry._1(setExpansionModal, (function (param) {
                                
                              }));
                }),
              token: token,
              aperture: aperture,
              attribute: expansionModal[1],
              handleTask: handleTask,
              projectName: projectName,
              handleAddFilters: handleAddFilters,
              handleChangeUrl: handleChangeUrl,
              factorName: "fingerprint",
              factorValue: expansionModal[0]
            })
    ) : null;
  return React.createElement(React.Fragment, undefined, React.createElement(Paper.default, {
                  className: Css.merge(/* :: */[
                        BtPaper$BsConsole.mainViewMargins,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.marginBottom(/* zero */-789508312),
                                /* :: */[
                                  Css.overflow(/* hidden */-862584982),
                                  /* [] */0
                                ]
                              ]),
                          /* [] */0
                        ]
                      ]),
                  elevation: BtPaper$BsConsole.elevation,
                  children: null
                }, React.createElement(ViewNav$BsConsole.make, {
                      config: config,
                      aperture: aperture,
                      route: route,
                      handleChangeUrl: handleChangeUrl,
                      projectName: projectName
                    }), React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: /* spaceBetween */516682146,
                      className: Css.style(/* :: */[
                            Css.width(Css.pct(100)),
                            /* :: */[
                              Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
                              /* :: */[
                                Css.padding2(Css.px(4), Css.px(16)),
                                /* [] */0
                              ]
                            ]
                          ]),
                      children: React.createElement(SavedQueriesContainer$BsConsole.make, {
                            name: projectName,
                            token: token,
                            handleTask: handleTask,
                            handleChangeUrl: handleChangeUrl,
                            config: config,
                            query: queryForSavedQueryContainer,
                            mode: mode,
                            normBy: normBy,
                            renderTrigger: (function (param) {
                                var queries = param.queries;
                                var handleOpen = param.handleOpen;
                                var disabled = Belt_Option.getWithDefault(Belt_Option.map(queries, (function (savedQueries) {
                                            var mode = SavedQueriesModel$BsConsole.modeOfStringExn("inbox");
                                            var query = TriageUtils$BsConsole.makeQuery(stats, aperture, havings, view, sort, attributes, fold);
                                            var match = Current$BsConsole.get(mode, query, savedQueries);
                                            if (match) {
                                              return true;
                                            } else {
                                              return false;
                                            }
                                          })), false);
                                var tmp = {
                                  projectBookmarkedViews: param.projectBookmarkedViews,
                                  userBookmarkedViews: param.userBookmarkedViews,
                                  projectDefault: param.projectDefault,
                                  userDefault: param.userDefault,
                                  queries: queries,
                                  handleOpen: (function (param) {
                                      return Curry._2(handleOpen, undefined, undefined);
                                    }),
                                  handleChangeUrl: handleChangeUrl,
                                  currentMode: SavedQueriesModel$BsConsole.modeOfStringExn("inbox"),
                                  projectName: projectName
                                };
                                if (queryForSavedQueryContainer !== undefined) {
                                  tmp.query = Caml_option.valFromOption(queryForSavedQueryContainer);
                                }
                                return React.createElement(React.Fragment, undefined, React.createElement(SavedQueryBookmarkSelect$BsConsole.make, tmp), React.createElement(Button.default, {
                                                color: "primary",
                                                className: Css.style(/* :: */[
                                                      Css.marginRight(Css.px(8)),
                                                      /* :: */[
                                                        Css.display(/* flex */-1010954439),
                                                        /* :: */[
                                                          Css.alignItems(/* center */98248149),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]),
                                                disabled: disabled,
                                                onClick: (function (param) {
                                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* ViewManagerSaveView */120]));
                                                    return Curry._2(handleOpen, undefined, /* Save */0);
                                                  }),
                                                children: null
                                              }, React.createElement(Save.default, {
                                                    className: Css.style(/* :: */[
                                                          Css.marginRight(Css.px(8)),
                                                          /* [] */0
                                                        ]),
                                                    color: "inherit"
                                                  }), I18N$BsConsole.show(undefined, "Save view")));
                              })
                          })
                    })), tmp, React.createElement(Paper.default, {
                  className: BtPaper$BsConsole.mainViewMargins,
                  elevation: BtPaper$BsConsole.elevation,
                  children: null
                }, view ? null : React.createElement(Row2$BsConsole.make, {
                        justifyContent: /* spaceBetween */516682146,
                        className: Css.style(/* :: */[
                              Css.padding4(Css.px(16), Css.px(16), /* zero */-789508312, Css.px(16)),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement(Row2$BsConsole.make, {
                            className: "walkthrough--triage--filter-group",
                            children: null
                          }, DistributionValueSelect$BsConsole.hasAttribute(attributes, "error.type") ? React.createElement(DistributionValueSelect$BsConsole.make, {
                                  attribute: "error.type",
                                  label: "Error type",
                                  aperture: aperture,
                                  token: token,
                                  projectName: projectName,
                                  handleSetAperture: handleSetAperture,
                                  className: Css.style(/* :: */[
                                        Css.marginRight(Css.px(8)),
                                        /* [] */0
                                      ]),
                                  icon: React.createElement(MuiIcons$BsConsole.OctagonalError.make, {
                                        color: "inherit",
                                        fontSize: "inherit"
                                      }),
                                  view: "triage"
                                }) : null, DistributionValueSelect$BsConsole.hasAttribute(attributes, "uname.sysname") ? React.createElement(DistributionValueSelect$BsConsole.make, {
                                  attribute: "uname.sysname",
                                  label: "Platform",
                                  aperture: aperture,
                                  token: token,
                                  projectName: projectName,
                                  handleSetAperture: handleSetAperture,
                                  className: Css.style(/* :: */[
                                        Css.marginRight(Css.px(8)),
                                        /* [] */0
                                      ]),
                                  icon: React.createElement(MuiIcons$BsConsole.Platform.make, {
                                        color: "inherit",
                                        fontSize: "inherit"
                                      }),
                                  view: "triage"
                                }) : null, React.createElement(TriageStatusSelect$BsConsole.make, {
                                aperture: aperture,
                                token: token,
                                projectName: projectName,
                                handleSetAperture: handleSetAperture,
                                className: Css.style(/* :: */[
                                      Css.marginRight(Css.px(8)),
                                      /* [] */0
                                    ]),
                                view: "triage"
                              }), React.createElement(TriageAssigneeSelect$BsConsole.make, {
                                aperture: aperture,
                                username: config.user.username,
                                handleSetAperture: handleSetAperture,
                                className: Css.style(/* :: */[
                                      Css.marginRight(Css.px(8)),
                                      /* [] */0
                                    ]),
                                view: "triage"
                              }), React.createElement(TriageTagSelect$BsConsole.make, {
                                aperture: aperture,
                                token: token,
                                projectName: projectName,
                                handleSetAperture: handleSetAperture,
                                view: "triage"
                              })), React.createElement(NormalizeBySelect$BsConsole.make, {
                            aperture: aperture,
                            token: token,
                            projectName: projectName,
                            view: "triage",
                            normBy: normBy,
                            handleSetMetricGroup: handleSetMetricGroup,
                            metricsAttributes: metricsAttributes,
                            config: config
                          })), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.width(Css.pct(100)),
                            /* :: */[
                              Css.position(/* relative */903134412),
                              /* [] */0
                            ]
                          ])
                    }, React.createElement(TriageHeader$BsConsole.MultiSelectRow.Jsx3.make, {
                          selection: selection,
                          setSelection: (function (selection) {
                              return Curry._1(setSelection, (function (param) {
                                            return selection;
                                          }));
                            }),
                          aperture: aperture,
                          config: config,
                          projectUsers: match$8[0],
                          allTags: allTags,
                          token: token,
                          fetchIssues: fetchIssues,
                          projectName: projectName,
                          triageGroups: match$8[1],
                          goToDetails: (function (fingerprint) {
                              return Curry._1(handleChangeUrl, Curry._1(handleAddFilters, /* :: */[
                                              /* tuple */[
                                                "fingerprint",
                                                /* Equal */Block.__(0, [/* `String */[
                                                      -976970511,
                                                      fingerprint
                                                    ]])
                                              ],
                                              /* [] */0
                                            ]));
                            }),
                          workflows: workflows,
                          handleChangeUrl: handleChangeUrl,
                          refreshAllTags: refreshAllTags,
                          sort: sort
                        }), React.createElement(ErrorsOverTimeBinChart$BsConsole.make, {
                          token: token,
                          projectName: projectName,
                          aperture: apertureForQuery,
                          onRangeChange: (function (range) {
                              return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                                            projectName,
                                            Route$BsConsole.getInboxParams(projectName, Caml_option.some(Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Custom */Block.__(1, [range]), aperture)), Caml_option.some(havings), undefined, Caml_option.some(fold), sort, normBy, undefined, undefined),
                                            undefined
                                          ]));
                            }),
                          className: Css.style(/* :: */[
                                Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                                /* [] */0
                              ]),
                          normBy: normBy,
                          metricsAttributes: metricsAttributes
                        })), tmp$1), tmp$9);
}

function make(fold, view, attributes, aperture, havings, config, sort, stats, token, handleTask, projectName, universe, handleChangeUrl, handleAddFilters, route, normBy, similarity, metricsAttributesOpt, stabilityScoreOpt, maybeWfLinkTx, _children) {
  var metricsAttributes = metricsAttributesOpt !== undefined ? metricsAttributesOpt : /* [] */0;
  var stabilityScore = stabilityScoreOpt !== undefined ? Caml_option.valFromOption(stabilityScoreOpt) : undefined;
  return ReasonReactCompat.wrapReactForReasonReact(ProjectTriage, {
              fold: fold,
              view: view,
              attributes: attributes,
              havings: havings,
              aperture: aperture,
              config: config,
              sort: sort,
              stats: stats,
              token: token,
              handleTask: handleTask,
              projectName: projectName,
              universe: universe,
              handleChangeUrl: handleChangeUrl,
              handleAddFilters: handleAddFilters,
              route: route,
              normBy: normBy,
              similarity: similarity,
              metricsAttributes: metricsAttributes,
              stabilityScore: stabilityScore,
              maybeWfLinkTx: maybeWfLinkTx
            }, _children);
}

var Re = {
  make: make
};

var Group;

var make$1 = ProjectTriage;

exports.Group = Group;
exports.Style = Style;
exports.stripTimestampFilters = stripTimestampFilters;
exports.getDetailsFingerprintFromAperture = getDetailsFingerprintFromAperture;
exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
